import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import HealthKit, {
  HKAuthorizationRequestStatus,
  HKCategorySampleRaw,
  HKCategoryTypeIdentifier,
} from '@kingstinct/react-native-healthkit';
import { subDays } from 'date-fns';
import { splitEvery } from 'ramda';

import { HealthKitSleep } from '@bighealth/api';

import { useCallbackWhenForegrounded } from 'lib/hooks';
import * as reporter from 'lib/reporter';

import {
  maxNumOfEntriesToRequestFromHealthKit,
  numberOfDaysForHealthKitSleepDataRequest,
} from '../config';
import { getNextNoon } from '../helpers';

export type HealthKitContextType = 'HealthKitContext';

export type HealthKitContextValue = {
  /**
   * # HealthKitAuthorizationRequestStatus
   *
   * Provides information about if we should request HealthKit (HealthKit) authorization
   *
   * ## Apple Authorization Request Status
   * @see https://developer.apple.com/documentation/healthkit/hkauthorizationrequeststatus
   *
   * Excerpt from Apple developer site
   * > - `case unknown` - The authorization request status could not be determined because an error occurred.
   * > - `case shouldRequest` - The application has not yet requested authorization for all the specified data types.
   * > - `case unnecessary` - The application has already requested authorization for all the specified data types.
   *
   * ## We will not be able to differentiate Denied from No Data
   *
   * These cases are indistinguishable, for privacy reasons
   * - a user who denied authorization
   * - from one who allowed authorization but has no with no data
   *
   * For more on this @see https://developer.apple.com/documentation/healthkit/hkauthorizationstatus
   *
   * Excerpt from above link
   * > To help maintain the privacy of sensitive health data,
   * > HealthKit does not tell you when the user denies your app permission to query data.
   * > Instead, it simply appears as if HealthKit does not have any data matching your query.
   * > Your app will receive only the data that it has written to HealthKit.
   * > Data from other sources remains hidden from your app."
   */
  HealthKitAuthorizationRequestStatus: HKAuthorizationRequestStatus | null;

  /**
   * This will show the most recent HealthKit Sleep Data payload for the time duration set in config
   *
   * @type readonly HealthKitCategorySampleRaw<HealthKitCategoryTypeIdentifier.sleepAnalysis>[]
   */
  recentHealthKitSleepDataPayload: readonly HKCategorySampleRaw<
    HKCategoryTypeIdentifier.sleepAnalysis
  >[];

  /**
   * For some reason, it takes a long time for the native HealthKit Auth modal to show up,
   *   so this will enable us to know if it's still pending
   *
   * Generally this will be used to determining if a spinner should be shown
   */
  isWaitingForNativeHealthKitAuthorizationPrompt: boolean;

  /**
   * Request HealthKit Sleep Authorization
   *
   * - will update `HealthKitAuthorizationRequestStatus`
   *
   * @async
   * @returns Promise<HealthKitAuthorizationRequestStatus>
   */
  requestHealthKitSleepAuthorization: () => Promise<
    HKAuthorizationRequestStatus
  >;

  /**
   * Request HealthKit Sleep Data
   * (async)
   *
   * - If it fails, it might retry the auth request.
   * - Will update `recentHealthKitSleepDataPayload`
   *
   * @async
   * @returns Promise<readonly HealthKitCategorySampleRaw<HealthKitCategoryTypeIdentifier.sleepAnalysis>[]>
   */
  requestHealthKitSleepData: (config: {
    numDaysOfData?: number;
    retry?: boolean;
  }) => Promise<
    | readonly HKCategorySampleRaw<HKCategoryTypeIdentifier.sleepAnalysis>[]
    | ReturnType<typeof createHealthKitSleepDataRequestError>
  >;

  /**
   * Request HealthKit Sleep Data
   * (async)
   *
   * - If it fails, it might retry the auth request.
   * - Will update `recentHealthKitSleepDataPayload`
   *
   * @async
   * @returns Promise<readonly HealthKitCategorySampleRaw<HealthKitCategoryTypeIdentifier.sleepAnalysis>[]>
   */
  postHealthKitSleepDataToBE: () => Promise<unknown> | void;

  sendDataToDeviceHealthKit: () => Promise<unknown>;
};
export const initialHealthKitContextValue = {
  HealthKitAuthorizationRequestStatus: HKAuthorizationRequestStatus.unknown,
  isWaitingForNativeHealthKitAuthorizationPrompt: false,
  recentHealthKitSleepDataPayload: [],
  requestHealthKitSleepAuthorization: async () =>
    HKAuthorizationRequestStatus.unknown,
  requestHealthKitSleepData: async () => [],
  postHealthKitSleepDataToBE: async () => undefined,
  sendDataToDeviceHealthKit: async () => undefined,
};

export const HealthKitContext = React.createContext<HealthKitContextValue>(
  initialHealthKitContextValue
);

const createHealthKitSleepDataRequestError = (message = '') =>
  new Error(`HealthKitSleepDataRequestError ${message}`);

const logHealthKitAuthError = (err: string) =>
  reporter.error('Unexpected error requesting HealthKit authorization.', err);

const logHealthKitDataError = (err: string) =>
  reporter.error(
    'Unexpected error getting HealthKit data. Will attempt to request HealthKit authorization.',
    err
  );

export const HealthKitContextProvider = ({
  requestHealthKitWritePermissions,
  children,
}: {
  requestHealthKitWritePermissions?: boolean;
  children: ReactNode;
}): ReactElement => {
  /**
   * Separate out the logic from hooking this up to the Provider
   */
  const healthKitAuthorizationContext = useHealthKitContext({
    requestHealthKitWritePermissions,
  });

  return (
    <HealthKitContext.Provider value={healthKitAuthorizationContext}>
      {children}
    </HealthKitContext.Provider>
  );
};

export const useHealthKitContext = ({
  requestHealthKitWritePermissions,
}: {
  requestHealthKitWritePermissions?: boolean;
}): HealthKitContextValue => {
  const [
    recentHealthKitSleepDataPayload,
    setRecentHealthKitSleepDataPayload,
  ] = useState<
    readonly HKCategorySampleRaw<HKCategoryTypeIdentifier.sleepAnalysis>[]
  >([]);

  const [
    isWaitingForNativeHealthKitAuthorizationPrompt,
    setIsWaitingForNativeHealthKitAuthorizationPrompt,
  ] = useState(false);

  const [
    authorizationRequestStatus,
    requestAuthorization,
  ] = HealthKit.useHealthkitAuthorization(
    // read permissions
    [HKCategoryTypeIdentifier.sleepAnalysis],
    // write permissions -- SHOULD ONLY BE PROVIDED IN DEV/QA
    requestHealthKitWritePermissions
      ? [HKCategoryTypeIdentifier.sleepAnalysis]
      : undefined
  );

  const requestHealthKitSleepAuthorization: HealthKitContextValue['requestHealthKitSleepAuthorization'] = useCallback(async () => {
    setIsWaitingForNativeHealthKitAuthorizationPrompt(true);

    try {
      const authResponse = await requestAuthorization();
      setIsWaitingForNativeHealthKitAuthorizationPrompt(false);

      return authResponse;
    } catch (err) {
      logHealthKitAuthError(err);
      return HKAuthorizationRequestStatus.unknown;
    }
  }, [requestAuthorization]);

  const requestHealthKitSleepData: HealthKitContextValue['requestHealthKitSleepData'] = useCallback(
    async ({
      numDaysOfData = numberOfDaysForHealthKitSleepDataRequest,
      retry = true,
    }) => {
      // abort unless request status is shouldRequest or unknown
      if (
        authorizationRequestStatus !== HKAuthorizationRequestStatus.unnecessary
      ) {
        const errorMessage =
          'Not requesting data because authorization status is not "unnecessary". ' +
          `Request status is: "${authorizationRequestStatus}"`;
        logHealthKitDataError(errorMessage);
        return createHealthKitSleepDataRequestError(errorMessage);
      }
      try {
        const nextNoon = getNextNoon();
        const sleepSample = await HealthKit.queryCategorySamples(
          HKCategoryTypeIdentifier.sleepAnalysis,
          {
            from: subDays(nextNoon, numDaysOfData > 365 ? 365 : numDaysOfData),
            to: nextNoon,
          }
        );

        setRecentHealthKitSleepDataPayload(sleepSample);
        return sleepSample;
      } catch (err) {
        logHealthKitDataError(err);
        // await requestHealthKitSleepAuthorization();
        if (retry) {
          await requestHealthKitSleepAuthorization();
          return await requestHealthKitSleepData({
            retry: false,
            numDaysOfData,
          });
        } else {
          return createHealthKitSleepDataRequestError(err);
        }
      }
    },
    [authorizationRequestStatus, requestHealthKitSleepAuthorization]
  );

  const postHealthKitSleepDataToBE = useCallback(async () => {
    if (recentHealthKitSleepDataPayload.length) {
      try {
        const hkDataChunks = splitEvery(
          maxNumOfEntriesToRequestFromHealthKit,
          recentHealthKitSleepDataPayload
        );

        const hkCreateEntriesCalls = hkDataChunks.map(data =>
          HealthKitSleep.create_bulk_with_user_id_and_entries(data)
        );

        await Promise.all(hkCreateEntriesCalls);
      } catch (e) {
        reporter.error('failed to write HealthKit data', e);
      }
    }
  }, [recentHealthKitSleepDataPayload]);

  const onForegroundCheck = useCallback(async () => {
    switch (authorizationRequestStatus) {
      // if we're waiting for an auth prompt
      case HKAuthorizationRequestStatus.shouldRequest:
        /**
         * GIVEN If native HK Auth prompt was shown and then dismissed (with or without answer)
         *  AND the app is expecting the auth value to be pending
         *  THEN request the auth value so we can update
         *    because the auth value has to be polled
         */
        if (isWaitingForNativeHealthKitAuthorizationPrompt) {
          try {
            // attempt to refresh the sleep auth values
            await requestHealthKitSleepAuthorization();
          } catch (err) {
            logHealthKitAuthError(err);
          }
        }
        break;
      /**
       * if we have already requested auth, we might want the most up to date data
       *
       * note that auth might have been denied and we'll just keep getting an empty array
       */
      case HKAuthorizationRequestStatus.unnecessary:
        try {
          // attempt to refresh the sleep auth values
          await requestHealthKitSleepData({});
        } catch (err) {
          logHealthKitDataError(err);
        }
    }
  }, [
    authorizationRequestStatus,
    isWaitingForNativeHealthKitAuthorizationPrompt,
    requestHealthKitSleepAuthorization,
    requestHealthKitSleepData,
  ]);

  const sendDataToDeviceHealthKit = useCallback(async () => {
    let fakeData: HealthKitSleep.get_test_data.Result = [];

    try {
      const res = await HealthKitSleep.get_test_data();
      fakeData = res.result;
    } catch (e) {
      reporter.error('failed to fetch fake HealthKit data', e);
    }

    const hkSavePromises: Promise<unknown>[] = [];

    if (fakeData.length) {
      fakeData.forEach(
        ({ startDate, endDate, metadata, value: sleepAnalysisValue }) => {
          hkSavePromises.push(
            HealthKit.saveCategorySample(
              HKCategoryTypeIdentifier.sleepAnalysis,
              sleepAnalysisValue,
              {
                start: new Date(startDate),
                end: new Date(endDate),
                metadata,
              }
            )
          );
        }
      );

      try {
        await Promise.all(hkSavePromises);
      } catch (e) {
        reporter.error('failed to write fake data to HealthKit', e);
      }
    }
  }, []);
  /**
   * when app is foregrounded (after native HealthKit Auth prompt or after typical backgrounding),
   *   re-check the values (by re-requesting them)
   */
  useCallbackWhenForegrounded(onForegroundCheck);

  return {
    HealthKitAuthorizationRequestStatus: authorizationRequestStatus,
    isWaitingForNativeHealthKitAuthorizationPrompt,
    recentHealthKitSleepDataPayload,
    requestHealthKitSleepAuthorization,
    requestHealthKitSleepData,
    postHealthKitSleepDataToBE,
    sendDataToDeviceHealthKit,
  };
};
