import { addHours, isFuture, set, startOfTomorrow } from 'date-fns';

import { timeOfDayForSleepCutoffInHours } from '../config';

export const getNextNoon = () => {
  const todayAtNoon = set(new Date(), {
    hours: timeOfDayForSleepCutoffInHours,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const tomorrowAtNoon = addHours(
    startOfTomorrow(),
    timeOfDayForSleepCutoffInHours
  );
  return isFuture(todayAtNoon) ? todayAtNoon : tomorrowAtNoon;
};
