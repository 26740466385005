import { useCallback, useEffect, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import { AppStateChangeHandler } from './types';

const backgroundStatuses: AppStateStatus[] = ['background', 'inactive'];
const activeStatus: Extract<AppStateStatus, 'active'> = 'active';
/**
 * Note that the callback function is executed synchronously
 * - If performing an async operation in the callback, you are strongly advised to liberally use `try/catch`
 */
export const useCallbackWhenForegrounded = (callback: () => void): void => {
  const [previousStatus, setPreviousStatus] = useState<AppStateStatus>(
    activeStatus
  );

  const checkStatusAndUpdate = useCallback<AppStateChangeHandler>(
    currentStatus => {
      if (
        currentStatus === activeStatus &&
        backgroundStatuses.includes(previousStatus)
      ) {
        callback();
      } else if (backgroundStatuses.includes(currentStatus)) {
        setPreviousStatus(currentStatus);
      }
      console.groupEnd();
    },
    [callback, previousStatus]
  );

  useEffect(() => {
    const subscription = AppState.addEventListener(
      'change',
      checkStatusAndUpdate
    );
    return function cleanupChangeHandler() {
      subscription.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkStatusAndUpdate]);
};
