import React, { useContext, useState } from 'react';
import { ScrollView } from 'react-native';
import { HKAuthorizationRequestStatus as RNHealthkitHKAuthorizationRequestStatus } from '@kingstinct/react-native-healthkit';
import Styled from 'styled-components/native';

import { Container } from 'components/Containers';
import { ButtonContainer } from 'components/Screens/ForQA/styled';
import { Text } from 'components/Text/Text';
import TextInput from 'components/TextInput';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
} from 'components/UniveralButtons';
import { Throbber } from 'sleepio/components/Throbber';

import { numberOfDaysForHealthKitSleepDataRequest } from '../config';
import { HealthKitContext } from '../context/HealthKitContext';

const styled = {
  Bold: Styled(Text)`
    font-weight: 700;
  `,
  Muted: Styled(Text)`
    color: #999;
  `,
};

export const DebugHealthKit = () => {
  const {
    HealthKitAuthorizationRequestStatus,
    isWaitingForNativeHealthKitAuthorizationPrompt,
    recentHealthKitSleepDataPayload,
    requestHealthKitSleepAuthorization,
    requestHealthKitSleepData,
    postHealthKitSleepDataToBE,
    sendDataToDeviceHealthKit,
  } = useContext(HealthKitContext);
  const [daysToRequest, setDaysToRequest] = useState(
    `${numberOfDaysForHealthKitSleepDataRequest}`
  );

  return (
    <ScrollView
      style={{
        flex: 1,
        paddingHorizontal: 20,
        paddingTop: 60,
        paddingBottom: 100,
        minHeight: 300,
        backgroundColor: 'white',
      }}
    >
      {isWaitingForNativeHealthKitAuthorizationPrompt ? (
        <Container style={{ flex: 1, height: 500 }}>
          <Throbber />
        </Container>
      ) : (
        <>
          <Text style={{ fontSize: 36 }}>HealthKit</Text>
          <ButtonContainer>
            <UniversalButton
              text={`Request HealthKit Access`}
              colorScheme={ButtonColorSchemes.Link}
              size={ButtonSizes.Small}
              onPress={requestHealthKitSleepAuthorization}
            />
          </ButtonContainer>
          <ButtonContainer>
            <styled.Bold>Days of data to request from HealthKit</styled.Bold>
            <TextInput
              onChangeText={(val: string) => {
                if (Number.isInteger(Number(val))) {
                  setDaysToRequest(val);
                }
              }}
              value={daysToRequest}
            />
            <UniversalButton
              text={`Request HealthKit Sleep Data (${daysToRequest})`}
              isDisabled={!daysToRequest}
              colorScheme={ButtonColorSchemes.Secondary}
              size={ButtonSizes.Small}
              onPress={() =>
                requestHealthKitSleepData({
                  numDaysOfData: Number(daysToRequest),
                })
              }
            />
          </ButtonContainer>
          <ButtonContainer>
            <UniversalButton
              text="Post HealthKit data to backend"
              colorScheme={ButtonColorSchemes.Secondary}
              size={ButtonSizes.Small}
              isDisabled={!recentHealthKitSleepDataPayload.length}
              onPress={postHealthKitSleepDataToBE}
            />
          </ButtonContainer>
          <ButtonContainer>
            <UniversalButton
              text="Save fake data to Device HealthKit"
              colorScheme={ButtonColorSchemes.Secondary}
              size={ButtonSizes.Small}
              onPress={sendDataToDeviceHealthKit}
            />
          </ButtonContainer>
          <Text>
            <styled.Bold>HealthKitAuthorizationRequestStatus </styled.Bold>
            {`${HealthKitAuthorizationRequestStatus}:${
              HealthKitAuthorizationRequestStatus === null
                ? 'null'
                : Object.values(RNHealthkitHKAuthorizationRequestStatus)[
                    HealthKitAuthorizationRequestStatus
                  ]
            }`}
          </Text>
          <Text>
            <styled.Bold>isWaitingForNativeHKAuthorizationPrompt </styled.Bold>
            {`(will show a spinner when 'true'): ${
              isWaitingForNativeHealthKitAuthorizationPrompt ? '✅' : '🚫'
            }`}
          </Text>
          <Text
            style={{
              marginTop: 10,
              paddingTop: 10,
              borderColor: 'gray',
              borderTopWidth: 1,
            }}
          >
            <styled.Bold>recentHKSleepDataPayload </styled.Bold>
            {`(past ${daysToRequest ||
              numberOfDaysForHealthKitSleepDataRequest} days): ${JSON.stringify(
              recentHealthKitSleepDataPayload,
              null,
              2
            )}`}
          </Text>
        </>
      )}
    </ScrollView>
  );
};

// TODO: export this withHealthKitContext(DebugHealthKit) and remove wrapping provider in JSX
