import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

import {
  HKCategorySampleRaw,
  HKCategoryTypeIdentifier,
} from '@kingstinct/react-native-healthkit';

export const create_bulk_with_user_id_and_entries = async (
  hkData: readonly HKCategorySampleRaw<HKCategoryTypeIdentifier.sleepAnalysis>[],
  requestConfig?: AxiosRequestConfig
): Promise<create_bulk_with_user_id_and_entries.Response> => {
  const request: create_bulk_with_user_id_and_entries.Request = {
    service_name: 'HealthKitSleep',
    service_version: '1',
    service_method: 'create_bulk_with_user_id_and_entries',
    args: {
      healthkit_entries: hkData,
    },
  };

  return await api(request, requestConfig);
};

export declare namespace create_bulk_with_user_id_and_entries {
  export interface Request extends APIRequestBody {
    service_name: 'HealthKitSleep';
    service_version: '1';
    service_method: 'create_bulk_with_user_id_and_entries';
    args: {
      healthkit_entries: readonly HKCategorySampleRaw<HKCategoryTypeIdentifier.sleepAnalysis>[];
    };
  }

  export type Result = {
    user_id: number;
    start_date_utc: string;
    end_date_utc: string;
    value: unknown;
    uuid: string;
    source_details: unknown;
    entry_metadata: unknown;
  }[];

  export type Response = APIResponse<Result>;
}

create_bulk_with_user_id_and_entries.queryKey =
  'HealthKitSleep/create_bulk_with_user_id_and_entries';
