import { throwIfMissingEnvVar } from 'config/envVars/helpers';

export const CC_ENABLED =
  throwIfMissingEnvVar('CC_ENABLED', process.env.CC_ENABLED) === '1';

export const CCMENU_ENABLED =
  throwIfMissingEnvVar('CCMENU_ENABLED', process.env.CCMENU_ENABLED) === '1';

export const LOCAL_NOTIFICATION_ENABLED =
  throwIfMissingEnvVar(
    'LOCAL_NOTIFICATION_ENABLED',
    process.env.LOCAL_NOTIFICATION_ENABLED
  ) === '1';

export const PLATGEN_FF_MFA_ENABLED =
  process.env.PLATGEN_FF_MFA_ENABLED === '1';

export const PLATGEN_FF_HEALTHKIT_ENABLED =
  process.env.PLATGEN_FF_HEALTHKIT_ENABLED === '1';

export const PUSH_NOTIFICATIONS_ENABLED =
  process.env.PUSH_NOTIFICATIONS_ENABLED === '1';
