import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

import { HKCategoryValueSleepAnalysis } from '@kingstinct/react-native-healthkit';

export const get_test_data = async (
  requestConfig?: AxiosRequestConfig
): Promise<get_test_data.Response> => {
  const request: get_test_data.Request = {
    service_name: 'HealthKitSleep',
    service_version: '1',
    service_method: 'get_test_data',
    args: {},
  };

  return await api(request, requestConfig);
};

export declare namespace get_test_data {
  export interface Request extends APIRequestBody {
    service_name: 'HealthKitSleep';
    service_version: '1';
    service_method: 'get_test_data';
  }

  export type Result = {
    endDate: Date;
    startDate: Date;
    value: HKCategoryValueSleepAnalysis;
    metadata: {
      HKTimeZone: string;
    };
  }[];

  export type Response = APIResponse<Result>;
}

get_test_data.queryKey = 'HealthKitSleep/get_test_data';
