export const numberOfDaysForHealthKitSleepDataRequest = 3;
export const timeOfDayForSleepCutoffInHours = 12;

/**
 * maxNumOfEntriesToRequestFromHealthKit was created with the following assumptions:
 * - a single HealthKit entry to be 1kb in size (estimated)
 * - a single night's sleep to have 100 entries (estimated)
 *   - therefor a single night's sleep is 100kb
 * - the API Gateway limit is 10mb
 *   - this works out to be a limit of 100 nights of sleep, or 10,000 entries
 * - to account for headers and other network overhead we will arbitrarily reduce the limit to 90 nights or 9,000 entries
 * - for an abundance of caution, since all of the above was based on assumptions, half the limit. Ergo, 4,500.
 */
export const maxNumOfEntriesToRequestFromHealthKit = 4500;
