import { AlertButton } from 'react-native';
import { History, LocationState } from 'history';
import { Dispatch } from 'redux';

import {
  OPEN_DAY,
  openDayValues,
} from 'components/SleepDiaryWeek/components/Presentation/utils/useTryOpenDate';
import { SLEEP_DIARY_ROUTE_PARAMETER } from 'components/SleepDiaryWeek/constants';
import { LOCAL_NOTIFICATION_ENABLED } from 'config/envVars';
import * as redirectorActions from 'cross-platform/react-router/Router/utils/Redirector/state/actions';
import {
  Redirection,
  RedirectorActionTypes,
} from 'cross-platform/react-router/Router/utils/Redirector/state/actions';
import { getReturnHomeLocation } from 'cross-platform/react-router/Router/utils/Redirector/utils/log/getReturnHomeLocation';
import {
  DEVELOPER_AV_SCENESET_ID,
  DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID,
  DEVELOPER_COMPONENTS_SCENESET_ID,
  DEVELOPER_RESPONSIVE_SCENESET_ID,
} from 'developer/constants';
import * as crashers from 'lib/debug/crashers';
import { openInAppBrowser } from 'lib/navigation/openInAppBrowser';
import {
  clearScreenReference,
  DebugAction,
  setScreenReference,
} from 'state/debug/actions';
import { store } from 'state/store';

import { qaConfigFlags } from './qaConfigFlags';

// Note: set defaults in qaConfigFlags.tsx
const createQAConfigFlagToggles = () =>
  qaConfigFlags.init([
    'Throw in SceneSet button', // Put "throw error" button in SceneSet (Test PG-1066)
    'Notifications every 10 secs', // Changes notifications frequency from daily to 10 seconds
    'Session notification after 10 seconds',
    'Notifications event logs', // Changes notifications frequency from daily to 10 seconds
    'Debug Clear for Date Pickers', // Put "Debug: clear" button above date/time/datetime inputs
    'MediaPlayer log', // Console.log for media playing logic
    'SD: Time on 2 lines', // Sleep Diary's SelectHorizontalTime input on two lines
    'SD: Force SelectHorizontal', // Sleep Diary's inputs are all select horizontal variants
  ]);
createQAConfigFlagToggles();

// This is a temporary helper function to support Auth0 logout needed for testing NHS login.
// Note - should be removed when "Auth0 logout" is removed from the QA menu options below.
const getAuth0LogoutUrl = (): string => {
  let logoutUrl = '';
  switch (process.env.STAGE) {
    case 'local':
    case 'dev':
      logoutUrl = 'https://bighealth-dev.us.auth0.com/v2/logout?';
      break;
    case 'stage':
    case 'qa':
      logoutUrl = 'https://bighealth-stage.us.auth0.com/v2/logout?';
      break;
    case 'prod':
      logoutUrl = 'https://bighealth-prod.us.auth0.com/v2/logout?';
      break;
    default:
      logoutUrl = 'https://bighealth-dev.us.auth0.com/v2/logout?';
      break;
  }
  return logoutUrl;
};

/**
 * Get list of Debug menu options for QA team to use
 *
 * For use with 2 different Debug menus:
 * - web: list of buttons
 * - iOS: Alert.alert
 *
 * @param productReference
 * @param history
 * @returns
 */
export const getQAOptions = (
  productReference: string,
  history: History<LocationState>,
  dispatch: Dispatch
): Array<AlertButton> => {
  switch (productReference) {
    case 'sleepio':
      return [
        {
          text: 'Goto /ssg/ss/',
          onPress: (): void =>
            history.push(`/${productReference}/qa/go/ssg-ss`),
        },
        {
          text: 'QA page',
          onPress: (): void =>
            history.push(`/${productReference}/qa/debug-menu`),
        },
        {
          text: 'Storybook',
          onPress: (): void =>
            history.push(
              `/${productReference}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/storybook`
            ),
        },
        {
          text: 'SleepDiary open "today"',
          onPress: (): void =>
            history.push(
              `/${productReference}/${SLEEP_DIARY_ROUTE_PARAMETER}?${OPEN_DAY}=${openDayValues.TODAY}`
            ),
        },
        {
          text: 'Goto /product',
          onPress: (): void =>
            history.push(`/${productReference}/qa/go/product`),
        },
        {
          text: 'Goto /settings/notifications',
          onPress: (): void =>
            history.push(`/${productReference}/settings/notifications`),
        },
        // This is a temporary solution until Auth0 logout functionality is implemented.
        {
          text: `Auth0 logout`,
          onPress: (): void => {
            openInAppBrowser(getAuth0LogoutUrl());
          },
        },
        {
          text: `Goto /login-callback with error`,
          onPress: (): void => {
            history.push(`/${productReference}/login-callback?test=error`);
          },
        },
        {
          text: '💟 HealthKit Test Page',
          onPress: (): void =>
            history.push(`/${productReference}/qa/healthkit-testing`),
        },
        {
          text: 'Old menu',
          onPress: (): void => history.push(`/${productReference}/menu`),
        },
        {
          text: 'Trigger redirection',
          onPress: (): void => {
            const location = {
              pathname: `/${productReference}/sleep-diary`,
              search: 'test=param',
            };
            const conditions: Redirection['conditions'] = [
              {
                type: 'LOCATION_MATCH',
                payload: getReturnHomeLocation(productReference),
              },
            ];
            const action = redirectorActions.push({
              location,
              conditions,
            });
            dispatch(action);
          },
        },
        {
          text: 'Remove all redirections',
          onPress: (): void => {
            dispatch({ type: RedirectorActionTypes.REMOVE_ALL });
          },
        },
        {
          text: 'Trigger native app crash',
          onPress: (): void => crashers.nativeCrash(),
        },
        {
          text: `Trigger EventService.fire_ui_event`,
          onPress: (): void => {
            history.push(`/${productReference}/qa/analytics`);
          },
        },
        {
          text: 'Trigger JS App crash',
          onPress: (): void => crashers.javascriptCrash(),
        },

        {
          text: 'Debug menu error log',
          onPress: (): void => console.error('Debug menu error log'),
        },
        LOCAL_NOTIFICATION_ENABLED
          ? {
              text: 'Notifications',
              onPress: (): void => {
                history.push(`/${productReference}/settings/notifications`);
              },
            }
          : {
              text: `Notifications FF: ${LOCAL_NOTIFICATION_ENABLED}`,
              onPress: (): void => undefined,
            },
        ...createQAConfigFlagToggles(),
        ...[undefined, 1, 2, 3].map(number => ({
          text: `Developer Components Test #${number || 'first'}`,
          onPress: (): void =>
            history.push(
              `/${productReference}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/${DEVELOPER_COMPONENTS_SCENESET_ID}${
                number ? `/${number}` : ''
              }`
            ),
        })),
        {
          text: 'Responsive Layout Test',
          onPress: (): void =>
            history.push(
              `/${productReference}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/${DEVELOPER_RESPONSIVE_SCENESET_ID}`
            ),
        },
        {
          text: 'AV Test',
          onPress: (): void =>
            history.push(
              `/${productReference}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/${DEVELOPER_AV_SCENESET_ID}`
            ),
        },
      ];
    case 'daylight':
      return [
        ...[
          'checkin_screen',
          'tense_and_release_lesson_screen',
          'worry_scheduling',
          'worry_time',
          'thought_challenger_(lesson)',
          'worry_exposure_(lesson)',
          'maintenance_screen',
        ].map(screen => ({
          text: `Show ${screen}`,
          onPress: (): DebugAction => {
            const action = store.dispatch(setScreenReference(screen));
            history.push(`/${productReference}/home`);
            return action;
          },
        })),
        {
          text: 'Clear test screen',
          onPress: (): DebugAction => {
            const action = store.dispatch(clearScreenReference());
            history.push(`/${productReference}/home`);
            return action;
          },
        },
        {
          text: 'Goto /ssg/ss/',
          onPress: (): void =>
            history.push(`/${productReference}/qa/go/ssg-ss`),
        },
        {
          text: '⚠️ Trigger native app crash',
          onPress: (): void => crashers.nativeCrash(),
        },
        {
          text: '⚠️ Trigger JS App crash',
          onPress: (): void => crashers.javascriptCrash(),
        },
        {
          text: '⚠️ Debug menu error log',
          onPress: (): void => console.error('Debug menu error log'),
        },
      ];
    default:
      return [];
  }
};
