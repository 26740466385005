import React, { ComponentProps, ReactElement, ReactNode } from 'react';

import {
  Circle as CircleType,
  Container as GenericContainerType,
  Image as ImageType,
  Link as LinkType,
  Modal as ModalType,
  PieChart as PieChartType,
  Rectangle as RectangleType,
  Star as StarType,
  Text as TextType,
  Video as VideoType,
} from '@bighealth/types/src/scene-components/client';

import { AccessibleWrapper } from 'components/AccessibleWrapper';
import { ResponseInput } from 'components/forms/ResponseOptions/ResponseInput';
import { ResponseTextInput } from 'components/forms/ResponseOptions/ResponseTextInput';
import { GenericContainer } from 'components/GenericContainer';
import { NotificationPermission } from 'components/NotificationPermission/NotificationPermission';
import { Quote } from 'components/Quote';
import { WeekTracker } from 'daylight/components/WeekTracker/WeekTracker';
import { WrapWithToggleVisibility } from 'lib/player/media/ToggleVisibilityOrchestrator/WrapWithToggleVisibility';

import Modal from '../Modal';

import Audio from './Media/Audio';
import Image from './Media/Image';
import Video from './Media/Video';
import { Link } from './Text/link';
import Button from './Button';
import { DebugThrow } from './DebugThrow';
import { ExtendSleepWindowForm } from './ExtendSleepWindow';
import { GoalsSceneComponent } from './Goals';
import MediaContainer from './MediaContainer';
import OverlayParagraph from './OverlayParagraph';
import PieChart from './PieChart';
import PlaceholderSpeechBubble from './PlaceholderSpeechBubble';
import { ProgressGoalsV2 } from './ProgressGoalsV2';
import Root from './Root';
import Scene from './Scene';
import SceneSet from './SceneSet';
import { ScheduleComponent } from './Schedule';
import { ScheduleNextSessionForm } from './ScheduleNextSession';
import { SetSleepWindowForm } from './SetSleepWindow';
import { Circle, Rectangle, Star } from './Shape';
import { SleepDiaryForm } from './SleepDiaryForm';
import { SleepDiaryWeekSceneComponent } from './SleepDiaryWeek';
import { Text } from './Text';

type ControlProps = Omit<ComponentProps<typeof ResponseInput>, 'component'>;
type ResponseTextInputProps = Omit<
  ComponentProps<typeof ResponseTextInput>,
  'component'
>;

const TextInput = (props: ResponseTextInputProps): ReactElement => (
  <ResponseTextInput {...props} />
);
const NumberInput = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'NumberInput'} />
);
const PhoneNumberInput = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'PhoneNumberInput'} />
);
const DateTimePicker = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'DateTimePicker'} />
);
const DatePicker = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'DatePicker'} />
);
const TimePicker = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'TimePicker'} />
);
const HorizontalScale = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'HorizontalScale'} />
);
const SelectHorizontal = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'SelectHorizontal'} />
);
const SelectHorizontalTime = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'SelectHorizontalTime'} />
);
const Dropdown = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'Dropdown'} />
);
const DropdownDurationPicker = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'DropdownDurationPicker'} />
);
const DropdownTimePicker = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'DropdownTimePicker'} />
);
const VerticalSelect = (props: ControlProps): ReactElement => (
  <ResponseInput {...props} component={'VerticalSelect'} />
);

const VisibilityModal = ({
  showAtSeconds,
  hideAtSeconds,
  ...rest
}: ModalType): ReactElement => (
  <WrapWithToggleVisibility
    showAtSeconds={showAtSeconds}
    hideAtSeconds={hideAtSeconds}
  >
    <Modal {...rest} />
  </WrapWithToggleVisibility>
);

const VisibilityText = ({
  showAtSeconds,
  hideAtSeconds,
  ...rest
}: TextType): ReactElement => (
  <WrapWithToggleVisibility
    showAtSeconds={showAtSeconds}
    hideAtSeconds={hideAtSeconds}
  >
    <AccessibleWrapper {...rest}>
      <Text {...rest} />
    </AccessibleWrapper>
  </WrapWithToggleVisibility>
);

const VisibilityVideo = ({
  showAtSeconds,
  hideAtSeconds,
  ...rest
}: VideoType): ReactElement => (
  <WrapWithToggleVisibility
    showAtSeconds={showAtSeconds}
    hideAtSeconds={hideAtSeconds}
  >
    <Video {...rest} />
  </WrapWithToggleVisibility>
);

const VisibilityImage = ({
  showAtSeconds,
  hideAtSeconds,
  ...rest
}: ImageType): ReactElement => (
  <WrapWithToggleVisibility
    showAtSeconds={showAtSeconds}
    hideAtSeconds={hideAtSeconds}
  >
    <Image {...rest} />
  </WrapWithToggleVisibility>
);

const VisibilityStar = ({
  showAtSeconds,
  hideAtSeconds,
  ...rest
}: StarType): ReactElement => (
  <WrapWithToggleVisibility
    showAtSeconds={showAtSeconds}
    hideAtSeconds={hideAtSeconds}
  >
    <Star {...rest} />
  </WrapWithToggleVisibility>
);

const VisibilityRectangle = ({
  showAtSeconds,
  hideAtSeconds,
  ...rest
}: RectangleType): ReactElement => (
  <WrapWithToggleVisibility
    showAtSeconds={showAtSeconds}
    hideAtSeconds={hideAtSeconds}
  >
    <Rectangle {...rest} />
  </WrapWithToggleVisibility>
);

const VisibilityCircle = ({
  showAtSeconds,
  hideAtSeconds,
  ...rest
}: CircleType): ReactElement => (
  <WrapWithToggleVisibility
    showAtSeconds={showAtSeconds}
    hideAtSeconds={hideAtSeconds}
  >
    <Circle {...rest} />
  </WrapWithToggleVisibility>
);

const VisibilityPieChart = ({
  showAtSeconds,
  hideAtSeconds,
  ...rest
}: PieChartType): ReactElement => (
  <WrapWithToggleVisibility
    showAtSeconds={showAtSeconds}
    hideAtSeconds={hideAtSeconds}
  >
    <PieChart {...rest} />
  </WrapWithToggleVisibility>
);

const VisibilityGenericContainer = ({
  showAtSeconds,
  hideAtSeconds,
  ...rest
}: GenericContainerType & { children: ReactNode }): ReactElement => (
  <WrapWithToggleVisibility
    showAtSeconds={showAtSeconds}
    hideAtSeconds={hideAtSeconds}
  >
    <GenericContainer {...rest} />
  </WrapWithToggleVisibility>
);

const VisibilityLink = ({
  showAtSeconds,
  hideAtSeconds,
  ...rest
}: LinkType): ReactElement => (
  <WrapWithToggleVisibility
    showAtSeconds={showAtSeconds}
    hideAtSeconds={hideAtSeconds}
  >
    <Link {...rest} />
  </WrapWithToggleVisibility>
);

const sceneSetComponentsDictionary = {
  Audio,
  Button,
  DatePicker,
  DateTimePicker,
  Dropdown,
  DropdownTimePicker,
  DropdownDurationPicker,
  HorizontalScale,
  Image: VisibilityImage,
  MediaContainer,
  Modal: VisibilityModal,
  NumberInput,
  PhoneNumberInput,
  OverlayParagraph,
  PlaceholderSpeechBubble,
  Root,
  Scene,
  SceneSet,
  NotificationPermission,
  TextInput,
  TimePicker,
  VerticalSelect,
  SelectHorizontal,
  SelectHorizontalTime,
  ProgressGoals: GoalsSceneComponent,
  ProgressGoalsV2,
  Schedule: ScheduleComponent,
  Video: VisibilityVideo,
  Text: VisibilityText,
  Link: VisibilityLink,
  Star: VisibilityStar,
  Rectangle: VisibilityRectangle,
  Circle: VisibilityCircle,
  PieChart: VisibilityPieChart,
  GenericContainer: VisibilityGenericContainer,
  SleepDiaryForm: SleepDiaryForm,
  SleepDiaryWeek: SleepDiaryWeekSceneComponent,
  SetSleepWindowForm: SetSleepWindowForm,
  ExtendSleepWindowForm: ExtendSleepWindowForm,
  ScheduleNextSessionForm: ScheduleNextSessionForm,
  DebugThrow: DebugThrow,
  Quote,
  WeekTracker,
};

export default sceneSetComponentsDictionary;
