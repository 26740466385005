import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components/native';

import accessibilityElements from 'common/constants/accessibilityElements';
import { NavFooter } from 'components/Footer';
import { SleepioLogoOutline, SleepioLogoWhite } from 'components/icons';
import Link from 'components/Link';
import { CloseButton } from 'components/Media/PlayerControls/CloseButton';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import { useHistory } from 'cross-platform/react-router';
import { accessibility } from 'cross-platform/utils/accessibilityProps';
import { roles } from 'cross-platform/utils/roleProps';
import { openURL } from 'lib/navigation/openURL';

import collapsedNavLogo from './config/collapsedNavLogo';
import { useShowLogo } from './hooks/useShowLogo';
import { useNavigationBarContext } from './providers/NavigationBarProvider';
import { LogoButton } from './LogoButton';
import { MenuDismisser } from './MenuDismisser';
import { MenuPortal } from './MenuPortal';
import {
  CategoryTitle,
  HamburgerContainer,
  HamBurgerIcon,
  ItemContainer,
  ItemContainerGroup,
  ItemIconContainer,
  Label,
  LogoContainer,
  MenuContainer,
  MenuItemsContainer,
  MenuSafe,
  NavBar,
  OpenButton,
  PortalStretcher,
  PressableNavItem,
  SessionIcon,
} from './styled';
import {
  isCategoryNavItem,
  isLinkNavItem,
  isPressableNavItem,
  NavEntry,
  NavigationTheme,
} from './types';
import { useGetNavigationConfig } from './useGetNavigationConfig';

type ActiveMenu = {
  items: NavEntry[];
};

export const CollapsedNav = (): ReactElement => {
  const dispatch = useDispatch();
  const showLogo = useShowLogo(collapsedNavLogo.showForScreens);
  const config = useGetNavigationConfig();
  const { enableMobileMode, navigationTheme } = useNavigationBarContext();
  const { screenHeight, screenWidth } = useContext(
    ScreenResponsiveLayoutContext
  );
  const theme = useContext(ThemeContext);
  // NOTE: we are moving to a responsive layout not dependant device detection for navigation
  const isTransparentNav = enableMobileMode;
  const [menu, setMenu] = useState<ActiveMenu | null>(null);
  const history = useHistory();
  const path = history.location.pathname;
  const cachedPath = useRef(path);

  useEffect(() => {
    // Lazy way of dismissing the menu without having to intercept click events on links etc
    setMenu(null);
    cachedPath.current = path;
  }, [dispatch, path]);

  const generateItems = (items: NavEntry[]): (ReactElement | null)[] => {
    return items
      .map((item): ReactElement | null => {
        const { label } = item;
        if (isPressableNavItem(item)) {
          return (
            <PressableNavItem
              onPress={item.onPress}
              key={label}
              testID={`Pressable-${label}`}
              accessibilityRole="button"
              role="button"
              aria-label={label}
              accessibilityLabel={label}
            >
              <Label type={item.type}>{label}</Label>
            </PressableNavItem>
          );
        } else if (isLinkNavItem(item)) {
          if (item.label === 'Sessions') {
            return (
              <ItemIconContainer
                role="menuitem"
                accessibilityRole="menuitem"
                key={label}
              >
                <Link
                  to={item.to}
                  title={`Go To ${label}`}
                  // Annoyingly testing-library/react-native can't select links via title property
                  // so we use testID instead
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  testID={`Go To ${label}`}
                  role="button"
                  accessibilityRole="button"
                >
                  <Label
                    role="link"
                    accessibilityRole="link"
                    href={item.to}
                    type={item.type}
                  >
                    {label}
                  </Label>
                </Link>
                <SessionIcon />
              </ItemIconContainer>
            );
          }
          return (
            <ItemContainer
              role="menuitem"
              accessibilityRole="menuitem"
              key={label}
            >
              {item.external ? (
                <Label
                  role="button"
                  accessibilityRole="button"
                  href={item.to}
                  type={item.type}
                  onPress={() => openURL(item.to)}
                >
                  {label}
                </Label>
              ) : (
                <Link
                  to={item.to}
                  title={`Go To ${label}`}
                  // Annoyingly testing-library/react-native can't select links via title property
                  // so we use testID instead
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  testID={`Go To ${label}`}
                  role="button"
                  accessibilityRole="button"
                >
                  <Label
                    role="button"
                    accessibilityRole="button"
                    href={item.to}
                    type={item.type}
                  >
                    {label}
                  </Label>
                </Link>
              )}
            </ItemContainer>
          );
        } else if (isCategoryNavItem(item)) {
          return (
            <ItemContainerGroup key={label}>
              <CategoryTitle type={'category'}>{label}</CategoryTitle>
              {generateItems(item.subMenu)}
            </ItemContainerGroup>
          );
        }
        return null;
      })
      .filter(Boolean);
  };
  return (
    <>
      <NavBar
        testID={'CollapsedNav'}
        style={{
          backgroundColor: isTransparentNav
            ? 'transparent'
            : theme.color.overlay.primary,
        }}
      >
        {showLogo ? (
          <LogoContainer>
            <LogoButton>
              {isTransparentNav && navigationTheme !== NavigationTheme.Dark ? (
                <SleepioLogoOutline
                  height={39}
                  color={theme.color.overlay.primary}
                />
              ) : (
                <SleepioLogoWhite height={39} />
              )}
            </LogoButton>
          </LogoContainer>
        ) : null}
        <HamburgerContainer>
          <OpenButton
            aria-label="Open modal"
            accessibilityLabel="Open modal"
            accessibilityHint="Opens the navigation menu"
            testID={'HamburgerButton'}
            {...accessibility(accessibilityElements.Button)}
            onPress={() => {
              if (!menu && config) {
                if (config) {
                  setMenu({
                    items: config,
                  });
                }
              }
            }}
          >
            <HamBurgerIcon
              color={
                isTransparentNav && navigationTheme !== NavigationTheme.Dark
                  ? theme.color.overlay.primary
                  : theme.color.white
              }
            />
          </OpenButton>
        </HamburgerContainer>
      </NavBar>
      {menu ? (
        <MenuPortal>
          <PortalStretcher style={{ width: screenWidth }}>
            <MenuContainer style={{ height: screenHeight }}>
              <MenuItemsContainer>
                <MenuSafe>{generateItems(menu.items)}</MenuSafe>
              </MenuItemsContainer>
              <NavFooter />
            </MenuContainer>
            <MenuDismisser
              onDismiss={() => {
                setMenu(null);
              }}
            />
            <MenuSafe
              style={{
                flex: 1,
                backgroundColor: 'transparent',
                position: 'absolute',
                top: 20,
                right: 20,
              }}
            >
              <CloseButton
                {...roles('MenuCloseButton')}
                onPress={() => {
                  setMenu(null);
                }}
              />
            </MenuSafe>
          </PortalStretcher>
        </MenuPortal>
      ) : null}
    </>
  );
};
